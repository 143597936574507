import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = 'https://server.biokeep.brmja.com'
//window.api = 'http://127.0.0.1:3000' // comment this for production

// 
window.timezones = [
  {
    ar: '[Asia/Riyadh] السعودية, الرياض',
    en: 'Asia/Riyadh'
  },
  {
    ar: '[Africa/Cairo] مصر, القاهرة',
    en: 'Africa/Cairo'
  },
]

// Date Settings
var momenth = require("moment-hijri");
window.cdate = function (date) {
  if (localStorage.getItem('user')) {
    if (JSON.parse(localStorage.getItem('user')).date_type == 'hijri') {
      return momenth(date, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
    } else {
      return date;
    }
  }
}
// End Date Settings

// Language
import lang_array from './languages.json'
var lang = localStorage.getItem('lang')
window.e = (word) => {
  if (lang_array[word]) {
    if (lang == 'ar') {
      return lang_array[word].ar;
    } else {
      return lang_array[word].en;
    }
  } else {
    return word;
  }
}
// End Language

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
