<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  created() {
    var _g = this;
    if (
      !localStorage.getItem("lang") &&
      !window.location.href.includes("join")
    ) {
      _g.$router.push("/language");
    }
    if (localStorage.getItem("admin") != null) {
      $("body").append(`
      <div class='g__c_addmin alert alert-danger' style='
      position: fixed;
      top: 10px;
      right: 40%;
      width: 200px;
      z-index: 9999;
      text-align: center;
      '><h4><i class='fa fa-times' style='cursor: pointer' onclick='$(".g__c_addmin").remove()'></i> أنت الآن في وضع الإدارة</h4></div>
      `);
    }
    var i = setInterval(() => {
      var user = localStorage.getItem("user");
      if (!user) {
        if (
          window.location.pathname != "/login" &&
          window.location.pathname != "/register" &&
          window.location.pathname != "/forget" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/language" &&
          window.location.pathname != "/en" &&
          window.location.pathname != "/subuser" &&
          window.location.pathname != "/error-404" &&
          !window.location.href.includes("join") &&
          window.location.pathname != "/ar"
        ) {
          _g.$router.push("/login");
        }
      } else {
        if ($("#credits").length == 0) {
          var uu = JSON.parse(user);
          var val =
            "id: " + uu._id + " | phone: " + uu.phone + " | name: " + uu.name;
          $("body").append(`
      <input type='hidden' id='credits' value="${val}">
      `);
        }
        clearInterval(i);
        $.post(api + "/auth/check", {
          jwt: JSON.parse(user).jwt,
        })
          .then(function (data) {
            data = JSON.parse(data);
            if (data.status != 100 && data.status != 300) {
              _g.$router.push("/logout");
            } else {
              if (data.status == 300) {
                if (
                  window.location.pathname != "/activate" &&
                  window.location.pathname != "/logout"
                ) {
                  _g.$router.push("/activate");
                }
              } else {
                if (
                  JSON.stringify(data.response) != localStorage.getItem("user")
                ) {
                  location.reload();
                }
                localStorage.setItem("user", JSON.stringify(data.response));
                if (data.response.freedays_requests > 0) {
                  $("#freedays-requests").html(`
                    <span class='btn btn-sm btn-danger' style='padding: 5px'>${data.response.freedays_requests}</span>
                  `);
                }
              }
            }
          })
          .catch(function () {
            _g.$router.push("/logout");
          });
      }
    }, 100);
  },
};
</script>
