import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/devices/add',
      name: 'devices-add',
      component: () => import('@/views/devices/add.vue')
    },
    {
      path: '/devices/edit/:id',
      name: 'devices-edit',
      component: () => import('@/views/devices/edit.vue')
    },
    {
      path: '/devices/overview/:id',
      name: 'devices-overview',
      component: () => import('@/views/devices/overview.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/index.vue')
    },
    {
      path: '/users/add',
      name: 'users-add',
      component: () => import('@/views/users/add.vue')
    },
    {
      path: '/users/import',
      name: 'users-import',
      component: () => import('@/views/users/import.vue')
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/users/edit.vue')
    },
    {
      path: '/users/overview/:id',
      name: 'users-overview',
      component: () => import('@/views/users/overview.vue')
    },
    {
      path: '/users/app-devices',
      name: 'app-devices',
      component: () => import('@/views/users/app-devices.vue')
    },
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/admins/index.vue')
    },
    {
      path: '/admins/add',
      name: 'admins-add',
      component: () => import('@/views/admins/add.vue')
    },
    {
      path: '/admins/edit/:id',
      name: 'admins-edit',
      component: () => import('@/views/admins/edit.vue')
    },
    {
      path: '/reports/day',
      name: 'day-report',
      component: () => import('@/views/reports/day.vue')
    },
    {
      path: '/reports/between',
      name: 'between-report',
      component: () => import('@/views/reports/between.vue')
    },
    {
      path: '/reports/rejected',
      name: 'rejected-reports',
      component: () => import('@/views/reports/rejected.vue')
    },

    {
      path: '/reports/excel',
      name: 'download-excel-report',
      component: () => import('@/views/reports/excel.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/general/settings.vue')
    },
    {
      path: '/freedays',
      name: 'freedays',
      component: () => import('@/views/general/freedays.vue')
    }, {
      path: '/freedays-requests',
      name: 'freedays-requests',
      component: () => import('@/views/general/freedays-requests.vue')
    },
    {
      path: '/locations',
      name: 'locations',
      component: () => import('@/views/locations/index.vue')
    },
    {
      path: '/manual-attend',
      name: 'manual-attend',
      component: () => import('@/views/reports/manual-attend.vue')
    },
    {
      path: '/salary-report',
      name: 'salary-report',
      component: () => import('@/views/reports/salary-report.vue')
    },
    {
      path: '/settings-groups',
      name: 'settings-groups',
      component: () => import('@/views/general/settings-groups.vue')
    },
    {
      path: '/pay',
      name: 'pay',
      component: () => import('@/views/general/pay.vue')
    },
    {
      path: '/page/api',
      name: 'page-api',
      component: () => import('@/views/general/page.vue')
    },
    {
      path: '/page/support',
      name: 'page-support',
      component: () => import('@/views/general/page.vue')
    },
    {
      path: '/page/docs',
      name: 'page-docs',
      component: () => import('@/views/general/page.vue')
    },
    {
      path: '/page/another-services',
      name: 'page-another-services',
      component: () => import('@/views/general/page.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/subuser',
      name: 'subuser',
      component: () => import('@/views/auth/subuser.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/language',
      name: 'language',
      component: () => import('@/views/general/language.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/ar',
      name: 'ar',
      component: () => import('@/views/general/ar.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/en',
      name: 'en',
      component: () => import('@/views/general/en.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/views/auth/activate.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/join/:l/to/:t',
      name: 'join',
      component: () => import('@/views/general/join.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/auth/logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
